.pricing-container {
  display: flex;
  justify-content: center;
  padding: 50px;
  background-color: #f0f2f5;
}

.plans {
  display: flex;
  gap: 20px;
}

.plan-card {
  width: 300px;
  padding: 20px;
  text-align: center;
  position: relative;
  min-height: 350px;
}

.plan-title {
  margin-bottom: 10px;
}

.plan-price {
  margin: 20px 0;
}

.highlight-red {
  color: red;
}

.highlight-blue {
  color: blue;
}

.btn-red {
  border-color: red;
  background-color: red;
  color: white;
}

.btn-blue {
  border-color: blue;
  background-color: blue;
  color: white;
}
.btn {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
}
.benefit-item {
  margin: 0;
}

body {
  margin: 0;
}
body > iframe {
  display: none !important;
}

.ant-btn.ant-btn-success {
  background-color: #00ab7d;
  color: #fff;
}
.ant-btn.ant-btn-success:hover {
  background-color: #0ac18b;
}

.ant-btn.ant-btn-success {
  background-color: #00ab7d;
  color: #fff;
}
.ant-btn.ant-btn-success:hover {
  background-color: #0ac18b;
}
.pipeline-btn {
  width: 150px;
}
.text-center {
  text-align: center;
}
.w-full {
  width: 100%;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-4{
  margin-top: 3rem;;
}
.justify-center{
  justify-content: center;
}
.justify-end {
  justify-content: end;
}
.w-min-4 {
  min-width: 2rem;
}
.w-4 {
  width: 2rem;
}
.m-auto {
  margin: auto;
}
.mt-3 {
  margin-top: 1rem;
}
.hidden {
  display: none;
}
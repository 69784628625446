.pipe-node {
  width: 150px;
  border: 2px solid green;
  border-radius: 5px;
  padding: 10px;

  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.pipe-node label {
  display: block;
  color: #000;
  font-size: 12px;
}

.pipe-node.inprogress {
  border: none;
  background: linear-gradient(90deg, blue 50%, transparent 50%),
    linear-gradient(90deg, blue 50%, transparent 50%),
    linear-gradient(0deg, blue 50%, transparent 50%),
    linear-gradient(0deg, blue 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 16px 2px, 16px 2px, 2px 16px, 2px 16px;
  background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
  animation: dash 5s linear infinite;
}

@keyframes dash {
  to {
    background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
  }
}

.pipe-log-container .log-content {
  font-weight: 400;
}

.pipe-log-container .log-content p {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
}

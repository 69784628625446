.floatingedges {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.floatingedges .react-flow__handle {
  opacity: 0;
}

.react-flow__handle {
  width: 10px !important;
  height: 10px !important;
  border-radius: 3px;
  background-color: #000;
}

.react-flow__handle.completed {
  display: none;
}

.react-flow__handle.inprogress {
  background-color: blue;
}

.react-flow__handle.connecting {
  animation: bounce 1600ms infinite ease-out;
}
.edgebutton {
    width: 20px;
    height: 20px;
    background: #eee;
    border: 1px solid #999;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    line-height: 1;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
  
  .edgebutton:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
    border: 1px solid black;
  }
  
  .edgebutton-foreignobject main {
    background: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
  }

  .edgebutton.hidden {
    display: none;
  }
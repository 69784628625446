.pipe-default-node {
    width: 150px;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 10px;
    
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    position: relative;
  }
  
  .pipe-default-node label {
    display: block;
    color: #000;
    font-size: 12px;
  }

  .pipe-default-node .edgebutton {
    width: 20px;
    height: 20px;
    background: #eee;
    border: 1px solid #999;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    line-height: 1;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%,-50%);
  }
  
  .pipe-default-node .edgebutton:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
    border: 1px solid black;
  }
.group-container .group-title, .group-container .group-title a {
    color: rgba(0, 0, 0, 0.88) !important;
    background: #ffffff;
}
.group-container {
    position: relative;
    display: inline-block;
    width: calc(100% - 2px);
    background-color: #ffffff;
    border: 1px solid rgba(5, 5, 5, 0.06);
    border-radius: 8px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.group-meta.markdown {
    position: relative;
    width: 100%;
    font-size: 14px;
    border-radius: 0 0 6px 6px;
    -webkit-transition: background-color 0.4s;
    transition: background-color 0.4s;
}

.markdown {
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 2;
}

.group-title {
    position: absolute;
    top: -14px;
    padding: 1px 8px;
    color: #777;
    background: #ffffff;
    border-radius: 6px 6px 0 0;
    -webkit-transition: background-color 0.4s;
    transition: background-color 0.4s;
    -webkit-margin-start: 16px;
    margin-inline-start: 5px;
}

.group-description {
    padding: 18px 24px 12px;
}

.group-body {
    min-height: 100px;
    padding: 1rem;
}